import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Parex Ltd`}</h1>
    <p>{`Parex Ltd is a market leader for rendered facade systems offering a wide range of decorative solutions and ideas. In the UK Parex are a rapidly growing company in the rendering market.`}</p>
    <p>{`All Parex render systems and products are supplied ready to use and have a long-lasting and durable appearance. Commitment to quality is the backbone of the company ethos.`}</p>
    <p>{`From its beginning as a regional French facade products company in the early 1980’s, Parex Group has grown to be one of the foremost producers of industrial ready-to-use products for the construction and building industries in the world, operating 32 manufacturing facilities in 15 countries around the globe.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      